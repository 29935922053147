// ** Redux Imports
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  collection,
  doc,
  DocumentData,
  onSnapshot,
  orderBy,
  query,
  QueryDocumentSnapshot,
  where,
} from "firebase/firestore";
import _, { isEmpty } from "lodash";
import moment from "moment-timezone";
import type { Appointment } from "practicare/types/appointments.model";
import { AtSign, Calendar, StopCircle } from "react-feather";
import { Link } from "react-router-dom";
import { getCollectionName } from "src/config/utils";
import { db } from "../../config/firebase";
import { store } from "../store";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const changesets = require("diff-json");
// ** Constants
const TITLE_CHANGE_DETAILS = "Change appointment details";
const PENDING_STATUS = "Pending";

const notficicationStrings: any = {
  EMAIL_CHANGE_THERAPHIST: {
    title: "Notification - Email",
    noPermissionContent:
      "No permissions to send emails Email with information about rescheduling was not sent to the therapist",
    content:
      "An email with information about the change of date has been sent to the therapist",
  },
  EMAIL_AFTER_FIRST_VISIT: {
    title: "Notification - Email",
    noPermissionContent:
      "No permissions to send emails. Email after first visit was not sent to the client.",
    content: "An email was sent to the customer after the first visit.",
  },
  EMAIL_CHANGE_CUSTOMER: {
    title: "Notification - Email",
    noPermissionContent:
      "No permissions to send emails. Email with information about rescheduling was not sent to the client.",
    content:
      "An email with information about the change of date has been sent to the client.",
  },
  EMAIL_PAYMENT_ZERO: {
    title: "Notification - Email",
    noPermissionContent:
      "No permissions to send emails. Email with payment link was not sent.",
    content: "An email with a payment link has been sent.",
  },
  EMAIL_PAYMENT: {
    title: "Notification - Email",
    noPermissionContent:
      "No permissions to send emails. Email with payment link was not sent.",
    content: "An email with a payment link has been sent.",
  },
  EMAIL_PAYMENT_ONLINE: {
    title: "Notification - Email",
    noPermissionContent:
      "No permissions to send emails. Email with payment link was not sent.",
    content: "An email with a payment link has been sent.",
  },
  EMAIL_NO_SHOW_UP: {
    title: "Notification - Email",
    noPermissionContent:
      "No permissions to send emails, Email with no showup information was not sent",
    content: "An email with a no showup information has been sent.",
  },
  EMAIL_MEETING_INVITE: {
    title: "Notification - Email",
    noPermissionContent:
      "No permissions to send emails. Email with link to online visit was not sent.",
    content: "An email with a link to the online visit has been sent.",
  },
  EMAIL_FIRST_APPOINTMENT: {
    title: "Notification - Email",
    noPermissionContent:
      "No permissions to send emails. E-mail with welcome to the center was not sent.",
    content: "An email welcoming you to the center has been sent",
  },
  EMAIL_NEW_CUSTOMER: {
    title: "Notification - Email",
    noPermissionContent:
      "No permissions to send emails. Email with information to therapist about new client was not sent.",
    content:
      "An email informing the therapist about a new client has been sent.",
  },
  EMAIL_CANCELLED: {
    title: "Notification - Email",
    noPermissionContent:
      "No permissions to send emails. The cancellation email was not sent.",
    content:
      "Email sent to therapist informing them of appointment cancellation.",
  },
  VARIANT_CHANGE: {
    title: "Notification - Email",
    noPermissionContent:
      "No permissions to send emails. E-mail with information about change of visit option was not sent to the therapist.",
    content:
      "An email with information about the change of visit option has been sent to the therapist.",
  },
  EMAIL_CANCELLED_NOT_PAID: {
    title: "Notification - Email",
    noPermissionContent: "",
    content:
      "An email with information about the automatic cancellation of the visit has been sent to the customer.",
  },
  EMAIL_PAYMENT_CONFIRMATION_THERAPHIST: {
    title: "Notification - Email",
    noPermissionContent:
      "No permissions to send emails. Email with information to the therapist about payment was not sent.",
    content:
      "An email with information to the therapist that the Przelewy24 system has recorded payment for the visit has been sent.",
  },
  EMAIL_PAYMENT_CONFIRMATION_CUSTOMER: {
    title: "Notification - Email",
    noPermissionContent:
      "No permissions to send emails. Email with information to the customer about payment was not sent.",
    content:
      "An email with information to the customer that the Przelewy24 system has recorded payment for the visit has been sent.",
  },
  EMAIL_CANCELLED_BY_CUSTOMER_CONFIRMATION: {
    title: "Notification - Email",
    noPermissionContent:
      "No permissions to send emails. The cancellation email was not sent.",
    content:
      "Email sent to customer informing them of appointment cancellation.",
  },
  SMS_CANCELLED_CONFIRMATION_TO_CUSTOMER: {
    title: "Notification - SMS",
    noPermissionContent:
      "No permissions to send text messages. Text messages were not sent after the first visit.",
    content:
      "An SMS with confirmation of the visit cancellation was sent with the message below",
  },
  SMS_CANCELLED_PAID_CONFIRMATION_TO_CUSTOMER: {
    title: "Notification - SMS",
    noPermissionContent:
      "No permissions to send text messages. Text messages were not sent after the first visit.",
    content:
      "An SMS with confirmation of the visit paid cancellation was sent with the message below",
  },
  SMS_AFTER_FIRST_VISIT: {
    title: "Notification - SMS",
    noPermissionContent:
      "No permissions to send text messages. Text messages were not sent after the first visit.",
    content: "An SMS after the first visit was sent with the message below:",
  },
  SMS_REMINDER_FIRST_VISIT: {
    title: "Notification - SMS",
    noPermissionContent:
      "No permissions to send text messages. The text message reminding about the first visit was not sent.",
    content:
      "An SMS reminder about your first visit was sent with the message below:",
  },
  SMS_REMINDER: {
    title: "Notification - SMS",
    noPermissionContent:
      "No permissions to send text messages. The text message with the visit reminder was not sent.",
    content:
      "An SMS reminder about your appointment was sent with the message below:",
  },
  SMS_REMINDER_ONLINE: {
    title: "Notification - SMS",
    noPermissionContent:
      "No permissions to send text messages. The text message with the visit reminder was not sent.",
    content:
      "An SMS reminder about your appointment was sent with the message below:",
  },
  SMS_NEW_CUSTOMER: {
    title: "Notification - SMS",
    noPermissionContent:
      "No permissions to send SMS. SMS with information about new client was not sent.",
    content:
      "SMS with information about a new customer sent with the message below:",
  },
  SMS_NEW_CUSTOMER_ADMINISTRATION: {
    title: "Notification - SMS",
    noPermissionContent:
      "No permissions to send SMS. SMS with information about new client to administration was not sent.",
    content:
      "SMS with information about a new customer sent to administration with the message below:",
  },
};
// ** Types
interface TimelineObject {
  id: string;
  before?: Record<string, any>;
  originalBefore?: any;
  after?: Record<string, any>;
  originalAfter?: any;
  changedAt?: Date | string;
  type: string;
  updatedBy: any;
  createdBy: any;
  changer: Changer;
  isCompletedAt?: Date | null;
  noPermission?: boolean;
  customContent?: string;
}

interface Changer {
  id: string;
  display: string;
  isCustomer?: boolean;
}

export interface AppointmentDetailsState {
  data: Appointment | null;
  notifications: QueryDocumentSnapshot<DocumentData>[];
  transactions: QueryDocumentSnapshot<DocumentData>[];
  timeline: any[];
  updatedAt: string;
}

// ** Initial State
const initialState: AppointmentDetailsState = {
  data: null,
  notifications: [],
  transactions: [],
  timeline: [],
  updatedAt: Date.now().toString(),
};

// ** Translate Function
const translate = (type: string, value: string): string => {
  const dictionary: any = {
    "Payment type": {
      ONLINE: "Online",
      Przelewy24: "Online",
      OFFLINE: "Stationary",
      BANK_TRANSFER: "Bank transfer",
      CREDIT_CARD: "Credit card",
      CARD: "Credit card",
      CASH: "Cash",
      comment: "Comment",
      "N/A": "N/A",
    },
    "Payment Status": {
      COMPLETED_AUTOMATICALLY: "Paid",
      COMPLETED_MANUALLY: "Paid",
      PENDING: "Pending",
    },
    Status: {
      SCHEDULED: "Scheduled",
      COMPLETED: "Completed",
      CANCELED: "Canceled",
      "CANCELED-PAID": "Canceled paid",
    },
    "Cancel reason": {
      CUSTOMER: "Client cancellation",
      APPOINTMENT_MOVED: "Appointment moved to another date",
      THERAPIST_ILLNESS: "Therapist illness",
      THERAPIST_VACATION: "Therapist vacation",
      PROCESS_TERMINATION: "Process termination",
      CUSTOMER_ABSENT: "Client did not show up for appointment",
    },
    "Date change reason": {
      CUSTOMER: "Client cancellation",
      THERAPIST_ILLNESS: "Therapist illness",
      THERAPIST_VACATION: "Therapist vacation",
      THERAPIST_CHANGE: "Therapist rescheduled",
      THERAPY_RESIGNATION: "Therapy resignation",
      PROCESS_TERMINATION: "Process termination",
      CUSTOMER_ABSENT: "Client did not show up for appointment",
    },
  };

  return dictionary[type] ? dictionary[type][value] : value;
};

// ** Helper Functions
const createTimelineObject = (
  doc: QueryDocumentSnapshot<DocumentData>
): TimelineObject => {
  const timelineObject: TimelineObject = {
    id: doc.id,
    before: {},
    after: {},
    originalBefore: doc.data().before,
    originalAfter: doc.data().after,
    changedAt: doc.data().changedAt?.toDate(),
    type: "APPOINTMENT",
    updatedBy: doc.data().after?.updatedBy,
    createdBy: doc.data().before?.createdBy,
    changer: {
      id: "",
      display: "",
      isCustomer: false,
    },
  };

  if (!isEmpty(doc.data().before)) {
    timelineObject.before = {
      Customer:
        doc.data().before.customer?.lastName &&
        doc.data().before.customer?.firstName
          ? `${doc.data().before.customer.lastName} ${doc.data().before.customer.firstName}`
          : "",
      Location: doc.data().before.location?.name || "",
      Comment: doc.data().before.comment || "",
      "Discount amount": doc.data().before.discountAmount || "" || "",
      Room: doc.data().before.room?.name || "",
      Status: doc.data().before.appointmentStatus || "",
      Variant: doc.data().before.variant?.variant?.name || "",
      "Cancel reason": doc.data().before.cancelReason || "",
      "Date change reason": doc.data().before.dateChangeReason || "",
      "Payment Status": doc.data().before.paymentStatus || "",
      "Payment type": doc.data().before.paymentType || "",
      "Date and Time": moment(doc.data().before.dateTime?.toDate()).format(
        "YYYY-MM-DD HH:mm"
      ),
    };
  }

  if (!isEmpty(doc.data().after)) {
    timelineObject.after = {
      Customer:
        doc.data().after?.customer?.lastName &&
        doc.data().after?.customer?.firstName
          ? `${doc.data().after.customer.lastName} ${doc.data().after.customer.firstName}`
          : "",
      Location: doc.data().after.location?.name || "",
      Comment: doc.data().after.comment || "",
      "Discount amount": doc.data().after.discountAmount || "",
      Room: doc.data().after.room?.name || "",
      Status: doc.data().after.appointmentStatus || "",
      Variant: doc.data().after.variant?.variant?.name || "",
      "Cancel reason": doc.data().after.cancelReason || "",
      "Date change reason": doc.data().after.dateChangeReason || "",
      "Payment Status": doc.data().after.paymentStatus || "",
      "Payment type": doc.data().after.paymentType || "",
      "Date and Time": moment(doc.data().after.dateTime?.toDate()).format(
        "YYYY-MM-DD HH:mm"
      ),
    };
  }

  if (timelineObject.originalAfter.updatedBy) {
    if (timelineObject.originalAfter.updatedBy === "CUSTOMER") {
      timelineObject.changer.isCustomer = true;
      timelineObject.changer.id = timelineObject.after?.customer?.id;
      timelineObject.changer.display = `${timelineObject.originalAfter.customer?.lastName} ${
        timelineObject.originalAfter.customer?.firstName
      }`;
    } else {
      timelineObject.changer.id = timelineObject.originalAfter?.updatedBy?.id;
      timelineObject.changer.display = `${timelineObject.originalAfter.updatedBy?.lastName} ${
        timelineObject.originalAfter.updatedBy?.firstName
      }`;
    }
  } else {
    timelineObject.changer.id = timelineObject.originalAfter?.createdBy?.id;
    timelineObject.changer.display = `${timelineObject.originalAfter?.createdBy?.lastName} ${timelineObject.originalAfter?.createdBy?.firstName}`;
  }

  return timelineObject;
};

const generateTimelineContentAppointmentChange = (
  timelineObject: TimelineObject,
  i18nTranslate: any
): any[] => {
  const timelineContent: any[] = [];

  const differenceList = changesets.diff(
    timelineObject.before,
    timelineObject.after
  );
  console.log("timelineObject", timelineObject);
  if (differenceList.length > 0) {
    timelineContent.push({
      title: (
        <div>
          <span>{i18nTranslate(TITLE_CHANGE_DETAILS)}</span>
          {timelineObject.changedAt && (
            <span>
              {" - "}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                className="text-body ml-1 practicare-link"
                to={
                  timelineObject.changer.id !== "system"
                    ? `/${timelineObject.changer.isCustomer ? "customers" : "users"}/${timelineObject.changer.id}`
                    : ""
                }
              >
                {timelineObject.changer.display}
              </Link>
            </span>
          )}
        </div>
      ),
      icon: <Calendar size={14} />,
      meta: timelineObject.changedAt
        ? moment(timelineObject.changedAt).format("YYYY-MM-DD HH:mm")
        : PENDING_STATUS,
      dateTime: timelineObject.changedAt,
      customContent: (
        <div className="d-flex align-items-center">
          <ul className="list-unstyled">
            {changesets
              .diff(timelineObject.before, timelineObject.after)
              .map((d: any) => (
                <li className="mb-75">
                  <span className="fw-bolder me-25">
                    {i18nTranslate(d.key)}:{" "}
                  </span>
                  {(d.type === "update" || d.type === "delete") && (
                    <span>
                      {i18nTranslate(translate(d.key, d.oldValue)) ||
                        (d.oldValue !== "" && "-")}
                    </span>
                  )}
                  {d.type === "update" && d.oldValue !== "" && (
                    <span> {"->"} </span>
                  )}
                  {d.type !== "delete" && (
                    <span>
                      {i18nTranslate(translate(d.key, d.value)) || "-"}
                    </span>
                  )}
                </li>
              ))}
          </ul>
        </div>
      ),
    });
  }

  return timelineContent;
};

const updateTimeline = (
  notifications: QueryDocumentSnapshot<DocumentData>[],
  transactions: QueryDocumentSnapshot<DocumentData>[],
  isAdmin: boolean,
  i18nTranslate: any
): any[] => {
  const timelineObjects: any[] = [];

  if (isAdmin) {
    transactions.forEach((doc) => {
      const timelineObject = createTimelineObject(doc);
      const timelineContent = generateTimelineContentAppointmentChange(
        timelineObject,
        i18nTranslate
      );

      if (timelineContent.length > 0) {
        timelineObjects.push(...timelineContent);
      }
    });
  }

  notifications.forEach((doc) => {
    const timelineObject: TimelineObject = {
      ...(doc.data() as TimelineObject),
      id: doc.id,
      isCompletedAt:
        (doc.data().isCompletedAt && doc.data().isCompletedAt.toDate()) ||
        doc.data().createdAt?.toDate() ||
        "-",
      customContent: doc.data().smsContent || "",
      updatedBy: null,
      createdBy: doc.data().createdBy,
      changer: {
        id: doc.data().createdBy?.id || "",
        isCustomer: false,
        display: doc.data().createdBy
          ? `${doc.data().createdBy.lastName} ${doc.data().createdBy.firstName}`
          : "System",
      },
    };

    timelineObjects.push({
      title: (
        <div>
          <span>
            {i18nTranslate(notficicationStrings[timelineObject.type]?.title)}
          </span>
          {timelineObject.changer.id && (
            <span>
              {" - "}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                className="text-body ml-1 practicare-link"
                to={
                  timelineObject.changer.id !== "system"
                    ? `/users/${timelineObject.changer.id}`
                    : "#"
                }
              >
                {timelineObject.changer.display}
              </Link>
            </span>
          )}
        </div>
      ),
      content: !timelineObject.noPermission
        ? i18nTranslate(notficicationStrings[timelineObject.type]?.content)
        : i18nTranslate(
            notficicationStrings[timelineObject.type]?.noPermissionContent
          ),
      dateTime: timelineObject.isCompletedAt,
      customContent:
        timelineObject.customContent && !timelineObject.noPermission ? (
          <div className="d-flex align-items-center">
            <span>{timelineObject.customContent}</span>
          </div>
        ) : null,
      icon: timelineObject.noPermission ? (
        <StopCircle size={14} />
      ) : (
        <AtSign size={14} />
      ),
      meta: timelineObject.isCompletedAt
        ? moment(timelineObject.isCompletedAt).format("YYYY-MM-DD HH:mm")
        : PENDING_STATUS,
    });

    // Other notification types can be added similarly

    const timelineContent = generateTimelineContentAppointmentChange(
      timelineObject,
      i18nTranslate
    );

    if (timelineContent.length > 0) {
      timelineObjects.push(...timelineContent);
    }
  });

  return _.orderBy(timelineObjects, "dateTime", "desc");
};

// ** Slice
export const appointmentDetailsSlice = createSlice({
  name: "appointmentDetails",
  initialState,
  reducers: {
    setAppointment(state, action: PayloadAction<any>) {
      state.data = action.payload;
      state.updatedAt = Date.now().toString();
    },
    setNotifications(
      state,
      action: PayloadAction<{
        notifications: QueryDocumentSnapshot<DocumentData>[];
        isAdmin: boolean;
        i18nTranslate: any;
      }>
    ) {
      state.notifications = action.payload.notifications;
      state.timeline = updateTimeline(
        action.payload.notifications,
        state.transactions,
        action.payload.isAdmin,
        action.payload.i18nTranslate
      );
      state.updatedAt = Date.now().toString();
    },
    setTransactions(
      state,
      action: PayloadAction<{
        transactions: QueryDocumentSnapshot<DocumentData>[];
        isAdmin: boolean;
        i18nTranslate: any;
      }>
    ) {
      state.transactions = action.payload.transactions;
      state.timeline = updateTimeline(
        state.notifications,
        action.payload.transactions,
        action.payload.isAdmin,
        action.payload.i18nTranslate
      );
      state.updatedAt = Date.now().toString();
    },
  },
});

export const appointmentDetailsSubscription = {
  subAppointment: null,
  subNotifications: null,
  subTransactions: null,
  appointmentId: "",
  loading: false,
} as any;
// ** Subscription
export const subscribeToAppointmentDetails = (
  appointmentId: string,
  isAdmin: boolean,
  i18nTranslate: any
) => {
  if (appointmentId === "") {
    appointmentDetailsSubscription.loading = true;
    appointmentDetailsSubscription.appointmentId = false;
    appointmentDetailsSlice.actions.setAppointment(null);
    if (appointmentDetailsSubscription.subAppointment) {
      appointmentDetailsSubscription.subAppointment();
    }
    if (appointmentDetailsSubscription.subNotifications) {
      appointmentDetailsSubscription.subNotifications();
    }
    if (appointmentDetailsSubscription.subTransactions) {
      appointmentDetailsSubscription.subTransactions();
    }
    return;
  }
  if (appointmentDetailsSubscription.subAppointment) {
    if (
      appointmentId &&
      appointmentDetailsSubscription.appointmentId &&
      appointmentDetailsSubscription.appointmentId !== appointmentId
    ) {
      appointmentDetailsSubscription.subAppointment();
      appointmentDetailsSubscription.subNotifications();
      if (appointmentDetailsSubscription.subTransactions) {
        appointmentDetailsSubscription.subTransactions();
      }
    } else {
      return;
    }
  }

  appointmentDetailsSubscription.loading = true;

  appointmentDetailsSubscription.appointmentId = appointmentId;
  const collectionName = getCollectionName("appointments", store, isAdmin);
  try {
    appointmentDetailsSubscription.subAppointment = onSnapshot(
      doc(db, `${collectionName}/${appointmentId}`),
      (data) => {
        if (data.exists()) {
          store.dispatch(
            appointmentDetailsSlice.actions.setAppointment({
              id: data.id,
              ...data.data(),
              dateTime: data.data().dateTime.toDate(),
            })
          );
          appointmentDetailsSubscription.loading = false;
        }
      }
    );
    if (isAdmin) {
      appointmentDetailsSubscription.subTransactions = onSnapshot(
        query(
          collection(db, "transactions"),
          where("context.params", "==", {
            collectionId: "appointments",
            documentId: appointmentId,
          }),
          orderBy("changedAt", "desc")
        ),
        (data) => {
          store.dispatch(
            appointmentDetailsSlice.actions.setTransactions({
              transactions: data.empty ? [] : data.docs,
              isAdmin,
              i18nTranslate,
            })
          );
        }
      );
    }
    appointmentDetailsSubscription.subNotifications = onSnapshot(
      query(
        collection(db, "notifications"),
        where("appointementId", "==", appointmentId)
      ),
      (data) => {
        store.dispatch(
          appointmentDetailsSlice.actions.setNotifications({
            notifications: data.empty ? [] : data.docs,
            isAdmin,
            i18nTranslate,
          })
        );
      }
    );
  } catch (e) {
    console.error(e);
  }
};

export default appointmentDetailsSlice.reducer;
