import { collection, Timestamp } from "firebase/firestore";
import debounce from "lodash/debounce";
import moment from "moment-timezone";
import type { User } from "practicare/types/user.model";
import type { AppointmentAddModalAppointmentFormData } from "src/components/modals/appointmentAddModal.types";
import type { UserState } from "src/context/auth";
import { addDocWithUser, db } from "./firebase";
import { dayOfWeeksOptions } from "./utils";

export const blockTimeslot = debounce(
  async (
    appointmentDetails: AppointmentAddModalAppointmentFormData,
    theraphist: User,
    userState: UserState
  ) => {
    const { dateTime } = appointmentDetails;
    const dayOfWeek = moment(dateTime).isoWeekday();
    const dayOfWeekObject = dayOfWeeksOptions.find(
      (d) => d.value === dayOfWeek.toString()
    );
    const blockStartDate = moment(dateTime)
      .subtract(1, "d")
      .startOf("d")
      .toDate();
    const blockEndDate = moment(dateTime).add(1, "d").endOf("d").toDate();

    const blockStartHour = moment(dateTime).toDate();
    const blockEndHour = moment(dateTime).add(1, "h").toDate();

    const preparedData = {
      theraphist: {
        id: theraphist.id,
        avatar: theraphist.avatar || "",
        firstName: theraphist.firstName,
        lastName: theraphist.lastName,
        comment: "",
        isHiddenFromCalendar: theraphist.isHiddenFromCalendar,
      },
      calendarLabel: null,
      location: { id: "", name: "" },
      dayOfWeek: dayOfWeekObject,
      room: null,
      startDate: Timestamp.fromDate(blockStartDate),
      endDate: Timestamp.fromDate(blockEndDate),
      startHour: Timestamp.fromDate(blockStartHour),
      endHour: Timestamp.fromDate(blockEndHour),
      isTimeOff: true,
      type: { label: "Blokada", value: "BLOCK" },
    };

    await addDocWithUser(
      collection(db, "availability"),
      preparedData,
      userState
    );
  },
  300
);
