import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { orderBy } from "lodash";
import type { Payment } from "practicare/types/payment.model";
import { db } from "src/config/firebase";
import { getCollectionName } from "src/config/utils";
import { store } from "../store";

const payrollSub = {
  subPaymentsInSelectedTime: null as (() => void) | null,
  subPayrollForMonth: null as (() => void) | null,
  subPaymentsForPayroll: null as (() => void) | null,
  start: null as Date | null,
  end: null as Date | null,
  userId: "" as string | null,
  month: "" as string,
};

const summmarySub = {
  subPayrollForStatistics: null as (() => void) | null,
  month: "" as string,
};

export interface PayrollState {
  paymentsInSelectedTime: Payment[];
  paymentsInGivenPayroll: Payment[];
  payrollForMonth: any;

  payrollForStatistics: any[];
}

// Define initial state
const initialState: PayrollState = {
  paymentsInSelectedTime: [],
  paymentsInGivenPayroll: [],
  payrollForMonth: null,
  payrollForStatistics: [],
};

export const payrollSlice: any = createSlice({
  name: "payroll",
  initialState: initialState,
  reducers: {
    setPaymentsInSelectedTime: (state, action: PayloadAction<Payment[]>) => {
      state.paymentsInSelectedTime = action.payload;
    },
    setPaymentsInGivenPayroll: (state, action: PayloadAction<Payment[]>) => {
      state.paymentsInGivenPayroll = action.payload;
    },
    setPayrollForMonth: (state, action: PayloadAction<any>) => {
      state.payrollForMonth = action.payload;
    },
    setPayrollForStatistics: (state, action: PayloadAction<any>) => {
      state.payrollForStatistics = action.payload;
    },
  },
});

export const {
  setPaymentsInSelectedTime,
  setPaymentsInGivenPayroll,
  setPayrollForMonth,
  setPayrollForStatistics,
} = payrollSlice.actions;

const unsubscribe = () => {
  if (payrollSub.subPaymentsInSelectedTime) {
    payrollSub.subPaymentsInSelectedTime();
    payrollSub.subPaymentsInSelectedTime = null;
  }
  if (payrollSub.subPayrollForMonth) {
    payrollSub.subPayrollForMonth();
    payrollSub.subPayrollForMonth = null;
  }
  if (payrollSub.subPaymentsForPayroll) {
    payrollSub.subPaymentsForPayroll();
    payrollSub.subPaymentsForPayroll = null;
  }
};
export const subscribeToPayroll = (
  start: Date | null,
  end: Date | null,
  userId: string,
  month: string,
  isAdmin: boolean
) => {
  if (!start || !end || !userId || !month) {
    return;
  }
  if (payrollSub.subPaymentsInSelectedTime) {
    if (payrollSub.start !== start) {
      unsubscribe();
    } else if (payrollSub.end !== end) {
      unsubscribe();
    } else if (payrollSub.userId !== userId) {
      unsubscribe();
    } else if (payrollSub.month !== month) {
      unsubscribe();
    } else {
      return;
    }
  }
  payrollSub.start = start;
  payrollSub.end = end;
  payrollSub.userId = userId;
  payrollSub.month = month;

  store.dispatch(setPaymentsInSelectedTime([]));

  try {
    const constrainsPaymentsInSelectedTime = [
      where("theraphist.id", "==", userId),
      where("dateTime", ">=", start),
      where("dateTime", "<=", end),
    ];

    const collectionName = getCollectionName("payments", store, isAdmin);
    payrollSub.subPaymentsInSelectedTime = onSnapshot(
      query(
        collection(db, collectionName),
        ...constrainsPaymentsInSelectedTime
      ),
      (data) => {
        const payments: any[] = [];
        data.forEach((doc) => {
          payments.push({
            id: doc.id,
            ...doc.data(),
            dateTime: doc.data().dateTime.toDate(),
            appointment: doc.data().appointment
              ? {
                  ...doc.data().appointment,
                  dateTime: doc.data().appointment?.dateTime.toDate(),
                }
              : null,
          });
        });
        store.dispatch(
          setPaymentsInSelectedTime(
            orderBy(
              payments.filter((p) => !p.isDeleted),
              "dateTime",
              "desc"
            )
          )
        );
        return payments;
      }
    );
  } catch (e: any) {
    console.error(e);
  }

  store.dispatch(setPaymentsInGivenPayroll([]));

  try {
    const constrainsPaymentsInGivenPayroll = [
      where("theraphist.id", "==", userId),
      where("invoiceMonth", "==", month),
    ];

    const collectionName = getCollectionName("payments", store, isAdmin);
    payrollSub.subPaymentsForPayroll = onSnapshot(
      query(
        collection(db, collectionName),
        ...constrainsPaymentsInGivenPayroll
      ),
      (data) => {
        const payments: any[] = [];
        data.forEach((doc) => {
          payments.push({
            id: doc.id,
            ...doc.data(),
            dateTime: doc.data().dateTime.toDate(),
            appointment: doc.data().appointment
              ? {
                  ...doc.data().appointment,
                  dateTime: doc.data().appointment?.dateTime.toDate(),
                }
              : null,
          });
        });
        store.dispatch(
          setPaymentsInGivenPayroll(
            orderBy(
              payments.filter((p) => !p.isDeleted),
              "dateTime",
              "desc"
            )
          )
        );
        return payments;
      }
    );
  } catch (e: any) {
    console.error(e);
  }

  store.dispatch(setPayrollForMonth(null));

  try {
    const constrainsPaymentsInGivenPayroll = [
      where("isDeleted", "==", false),
      where("userId", "==", userId),
      where("invoiceMonth", "==", month),
    ];

    const collectionName = getCollectionName(
      "employeeInvoices",
      store,
      isAdmin
    );
    payrollSub.subPayrollForMonth = onSnapshot(
      query(
        collection(db, collectionName),
        ...constrainsPaymentsInGivenPayroll
      ),
      (data) => {
        if (data.size === 1) {
          store.dispatch(
            setPayrollForMonth({ ...data.docs[0].data(), id: data.docs[0].id })
          );
        }
        if (data.size === 0) {
          store.dispatch(setPayrollForMonth(null));
        }

        if (data.size > 1) {
          console.error("More than one invoice found for the month");
          store.dispatch(
            setPayrollForMonth({ ...data.docs[0].data(), id: data.docs[0].id })
          );
        }
      }
    );
  } catch (e: any) {
    console.error(e);
  }
};

export const subscribeToPayrollForStatistics = (month: string) => {
  if (summmarySub.subPayrollForStatistics) {
    if (summmarySub.month !== month) {
      summmarySub.subPayrollForStatistics();
    } else {
      return;
    }
  }

  summmarySub.month = month;
  summmarySub.subPayrollForStatistics = onSnapshot(
    query(
      collection(db, "employeeInvoices"),
      where("invoiceMonth", "==", month),
      where("isDeleted", "==", false)
    ),
    (data) => {
      const summaryData = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      store.dispatch(setPayrollForStatistics(summaryData));
    }
  );
};
export default payrollSlice.reducer;
