import { getAnalytics, setUserId } from "firebase/analytics";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  User,
} from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import moment from "moment-timezone";
import { User as UserModel } from "practicare/types/user.model";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { auth, db } from "../config/firebase";
interface AuthContextProps {
  user: UserState;
  isAdmin: boolean;
  login: (credentials: LoginCredentials) => Promise<void>;
  logout: () => void;
  refreshSelf: () => Promise<void>;
}

export interface UserState {
  authData: User | null;
  userData: UserModel | null;
  error: string | null;
  loading: boolean;
}

interface LoginCredentials {
  email: string;
  password: string;
}

const analytics = getAnalytics();

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [user, setUser] = useState<UserState>({
    authData: null,
    userData: null,
    error: null,
    loading: false,
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        (window as any).dataLayer = (window as any).dataLayer || [];
        (window as any).dataLayer.push({ event: "login", userId: user.uid });
        setUserId(analytics, user.uid);

        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        const userData = { ...(userDoc.data() as UserModel), id: user.uid };

        if (userData.timeZone) {
          moment.tz.setDefault(userData.timeZone);
        }
        const backgroundImage = (userData as any).backgroundImage;
        if (backgroundImage) {
          document.body.style.backgroundImage = `url(${backgroundImage})`;
        }
        setUser({ authData: user, userData, error: null, loading: false });

        if (userData.role === "ADMIN") {
          setIsAdmin(true);
          const adminPaths = ["/", "/login", "/resetPassword"];
          if (adminPaths.includes(location.pathname)) {
            navigate("/me");
          }
        } else {
          setIsAdmin(false);
          const userPaths = ["/", "/login", "/resetPassword"];
          if (userPaths.includes(location.pathname)) {
            navigate("/myCalendar");
          } else if (
            location.pathname !== "/register" &&
            location.pathname !== "/payment"
          ) {
            navigate(location.pathname);
          }
        }
      } else {
        setIsAdmin(false);
        setUser({
          authData: null,
          userData: null,
          error: null,
          loading: false,
        });

        const allowedPaths = [
          "/login",
          "/passwordForgot",
          "/loginWithEmail",
          "/loginWithToken",
          "/register",
          "/resetPassword",
          "/paymentType",
          "/start",
          "/paymentConfirmation",
          "/payment",
          "/terms",
          "/onlinePaymentForm",
        ];
        if (!allowedPaths.includes(location.pathname)) {
          navigate("/login", { replace: true });
        }
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  const login = async ({ email, password }: LoginCredentials) => {
    setUser((prevState) => ({ ...prevState, loading: true }));
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (e: any) {
      let errorMessage = "";
      switch (e.code) {
        case "auth/user-not-found":
          errorMessage = "User with this email has not been found.";
          break;
        case "auth/wrong-password":
          errorMessage = "Invalid password.";
          break;
        case "auth/too-many-requests":
          errorMessage =
            "Too many attempts. Reset your password or try again later.";
          break;
        default:
          errorMessage = "There was a problem with login.";
      }
      setUser({
        authData: null,
        userData: null,
        error: errorMessage,
        loading: false,
      });
      console.error("Login error:", e);
    }
  };

  const logout = async () => {
    await signOut(auth);
  };

  const refreshSelf = async () => {
    if (user.authData) {
      const userDocRef = doc(db, "users", user.authData.uid);
      const userDoc = await getDoc(userDocRef);
      const userData = {
        ...(userDoc.data() as UserModel),
        id: user.authData.uid,
      };

      if (userData.timeZone) {
        moment.tz.setDefault(userData.timeZone);
      }
      setUser((prevState) => ({ ...prevState, userData }));
    }
  };

  return (
    <AuthContext.Provider value={{ user, isAdmin, login, logout, refreshSelf }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
